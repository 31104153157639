import * as React from 'react'
import { Link } from 'gatsby'
import Button from '../Button/button'

const ServiceItemBox = ({ title, children, href, LinkTitle }) => {
  return (
    <div className="servicesItemBox__boxWrapper">
      <div className="servicesItemBox__imgWrapper">{children}</div>
      <div className="servicesItemBox__subHeading">{title}</div>
      <Link to={href} title={LinkTitle}>
        <center>
          <Button title="learn more about our restoration services" cta="Learn More" />
        </center>
      </Link>
    </div>
  )
}

export default ServiceItemBox
