import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/seo'

// images
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import ServiceItemBox from '../../components/service_images/services_item_box'

const ServicesPage = () => (
  <Layout>
    <Seo
      title="Ecotech Restoration and Repair Services"
      description="A list of our repair &amp; restoration services, including flood, fire and mold damage."
      canonical="https://ecotechrestoration.ca/services/"
    />

    {/* Heros */}
    <div className="heroWrapper">
      <StaticImage
        className="heroImage"
        src="../../images/heros/home.jpg"
        quality={95}
        alt="Ecotech Restoration List of Services"
      />
      <div className="overlay" />
      <h1 className="services__heading heroText">Our Services</h1>
    </div>

    <SiteWidthWrapper>
      <CallOut>For all of your repair and restoration needs</CallOut>

      <div className="textCenter">
        <p>
          Ecotech Restoration provides 24 hour restoration / repair services for
          home and business,{' '}
          <span className="services__alertPInline">including biohazards.</span>
        </p>

        <p>
          We have <strong>four</strong> locations to serve you from, including
          Vancouver, Maple Ridge, Port Coquitlam and Burnaby.
        </p>
        <p>
          All locations can be reached through our 24/7 contact number{' '}
          <a href="tel:16046835663" title="call ecotech restoration">
            604.683.5663
          </a>
          .
        </p>
      </div>

      <h2>List of Services</h2>

      <div className="services__servicesWrapper">
        <ServiceItemBox
          title="Water & Flood Restoration Services"
          href="/services/flood-damage-repair-and-restoration/"
          LinkTitle="Water & Flood Services">
          <StaticImage
            src="../../images/services/service_flood.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Flood Restoration Services"
            loading="eager"
          />
        </ServiceItemBox>

        <ServiceItemBox
          title="Fire Restoration Services"
          href="/services/fire-damage-repair-and-restoration/"
          LinkTitle="Fire Restoration Services">
          <StaticImage
            src="../../images/services/service_fire.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Fire Restoration Services"
          />
        </ServiceItemBox>

        <ServiceItemBox
          title="Mold Restoration Services"
          href="/services/mold-damage-repair-and-restoration/"
          LinkTitle="Mold Restoration and Repair">
          <StaticImage
            src="../../images/services/service_mold.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Mold Restoration Services"
          />
        </ServiceItemBox>

        <ServiceItemBox
          title="Exterior Drainage Restoration Services"
          href="/services/exterior-drainage-maintenance/"
          LinkTitle="Exterior Drainage Restoration Repair">
          <StaticImage
            src="../../images/services/service_toilet.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Exterior Drainage Restoration Services"
          />
        </ServiceItemBox>

        <ServiceItemBox
          title="Explosion Damage Repair"
          href="/services/explosion-damage-repair-and-restoration/"
          LinkTitle="Explosion Damage Repair">
          <StaticImage
            src="../../images/services/service_explos.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Explosion Damage Repair"
          />
        </ServiceItemBox>

        <ServiceItemBox
          title="Break-in Repair Services"
          href="/services/break-in-repair/"
          LinkTitle="Break-in Repair Services">
          <StaticImage
            src="../../images/services/service_broken.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Break-in Repair Services"
          />
        </ServiceItemBox>

        <ServiceItemBox
          title="Sewer Backup Damage Repair"
          href="/services/sewer-back-up-damage-repair-and-restoration/"
          LinkTitle="Sewer Backup Damage Repair">
          <StaticImage
            src="../../images/services/service_pipes.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Sewer Backup Damage Repair"
          />
        </ServiceItemBox>

        <ServiceItemBox
          title="Vandalism Repair Services"
          href="/services/vandalism-repair"
          LinkTitle="Vandalism Repair Services">
          <StaticImage
            src="../../images/services/service_graffiti.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Vandalism Repair Services"
          />
        </ServiceItemBox>

        <ServiceItemBox
          title="Wind Repair Repair Services"
          href="/services/wind-damage-repair-and-restoration/"
          LinkTitle="Wind Repair Services">
          <StaticImage
            src="../../images/services/service_wind.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Wind Repair Services"
          />
        </ServiceItemBox>

        <ServiceItemBox
          title="Asbestos Removal"
          href="/services/asbestos-removal/"
          LinkTitle="Wind Repair Services">
          <StaticImage
            src="../../images/services/service_asbestos.jpg"
            maxWidth={360}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="asbestos abatement Services"
          />
        </ServiceItemBox>
      </div>
    </SiteWidthWrapper>
  </Layout>
)

export default ServicesPage
