import * as React from 'react'
import {
    borderTop,
    borderBottom,
    firstP,
    secondP,
    thirdP,
} from './callOut.module.scss'

import SiteWidthWrapper from './wrappers/siteWidthWrapper'
import CallNow from './Button/call'

const CallOut = ({children}) => {
    return (
        <SiteWidthWrapper>
            {/* Makes a border */}
            <div className={borderTop}/>

            <p className={firstP}>WE ARE AVAILABLE 24/7</p>
            <p className={secondP}>{children}</p>
            <p className={thirdP}>
                Call us now and we'll dispatch someone within the hour.
            </p>
            <div>
                {/* phone number div */}
                <CallNow/>
                {/* phone number div end */}
            </div>

            {/* Makes a border */}
            <div className={borderBottom}/>
        </SiteWidthWrapper>
    )
}

export default CallOut
