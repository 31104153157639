import * as React from "react"
import {
	wrapper
} from "./siteWidthWrapper.module.scss"

const SiteWidthWrapper = ({children}) => {
	return (
		<div className={wrapper}>
			{children}
		</div>
	)
}

export default SiteWidthWrapper