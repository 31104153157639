import * as React from 'react'

import RedPhone from '../../images/menuStuff/red_phone.png'
import TwentyFourSeven from '../../images/menuStuff/247.png'

const CallNow = () => {
  return (
    <div className="callNowButton">
      <img
        className="twentyFourSevenPadding"
        src={TwentyFourSeven}
        width="64px"
        height="64px"
        alt="red background"
        loading="lazy"
      />
      <a
        href="tel:+16046835663"
        id="homeCall"
        title="call now for 24 hour emergency restoration services">
        <img
          src={RedPhone}
          width="200"
          height="64px"
          alt="call 6046835663 for emergency restoration"
          loading="lazy"
        />
      </a>
    </div>
  )
}

export default CallNow
